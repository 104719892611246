<template>
  <div class="">222</div>
</template>
<script>
export default {
  data () {
    return {
    }
  },
  mounted () {},
  methods: {}
}
</script>

<style scoped lang=""></style>
